<template>
  <Layout>
    <v-row class="fill-height d-flex flex-column" justify="center" align="center" >
        <img src="@/assets/logo-white.png" height="200" center />
        <h2 class="grey--text">Data Intelligence Platform</h2>
        <v-card class="grey lighten-1">
          <div class="d-flex flex-column mx-10 my-4">
  					<span class="poweredby primary--text mr-2">Powered by</span>
  					<v-img src="@/assets/logo-top.png" contain height="60" />
  				</div>
        </v-card>
    </v-row>
  </Layout>
</template>

<script>
export default {
  name: 'Dashboard'
}
</script>

<style lang="scss" scoped>

</style>